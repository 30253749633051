<template>
  <div class="content-box vision-box">
    <vHeader :isShowTitle="true" :showback="false" tittle='眼底黄斑检查'>
      <template slot="tab">
        <div style="display:flex;align-items: center;">
          <img src="../../assets/imgs/eye.png" alt="">{{ $store.state.eyeText }}
          <audio id="myAudio" autoplay="autoplay" hidden="true" controls ref="myAudio" @ended="overAudio">
            <source :src="playSrc" type="audio/mpeg" />
          </audio>
        </div>
      </template>
      <template v-if="currentIndex != 0" slot="right-icon">
        <div @click="upQuestion">
          上一题
        </div>
      </template>
    </vHeader>
    <div v-if="!curve" class='page-content hasTitle'>
      <!-- <div>111</div> -->
      <macula-page :currentIndex="currentIndex"></macula-page>
      <div class='align-left bottom-box'>
        <span class='base-color'>{{ data[currentIndex].no }}</span>{{ data[currentIndex].text }}
        <div class='flex flex-align-items flex-justify-content' style="margin-bottom:20px;">
          <span class='left-btn' @click="setRightEye">没有</span>
          <span class='right-btn' @click="goNext">有</span>
        </div>
      </div>
    </div>
    <macula-index-step-5 v-if="curve" :currentIndex="currentIndex" @changeIndex="changeIndex"></macula-index-step-5>
  </div>
</template>

<script>
import vHeader from '../../components/header.vue'
import maculaPage from '../../components/maculaPage.vue'
import MaculaIndexStep5 from './maculaIndexStep5.vue';

export default {
  components: {
    vHeader, maculaPage,
    MaculaIndexStep5
  },
  data() {
    return {
      currentIndex: 0,
      curve: false,
      isPlay: true,
      playSrc: '/assets/Amsler/audios/4.1.0.mp3',
      canvasWidth: 10 / (this.$store.state.sys_param.Dpi * 1 / this.$store.state.sys_param.PPI * 2.45),
      canvasHeight: 10 / (this.$store.state.sys_param.Dpi * 1 / this.$store.state.sys_param.PPI * 2.45),
      data: [{ no: 1, text: '方格图是否有异常?', playSrcLeft: '/assets/Amsler/audios/4.1.0.mp3', playSrcRight: '/assets/Amsler/audios/4.1.1.mp3' },
      { no: 2, text: '方格图是否有弯曲变形?', playSrcLeft: '/assets/Amsler/audios/4.2.0.mp3', playSrcRight: '/assets/Amsler/audios/4.2.1.mp3', playSrcHav: '/assets/Amsler/audios/4.2.2.mp3', },
      { no: 3, text: '方格图是否有模糊不清?', playSrcLeft: '/assets/Amsler/audios/4.3.0.mp3', playSrcRight: '/assets/Amsler/audios/4.3.1.mp3', playSrcHav: '/assets/Amsler/audios/4.3.2.mp3', },
      { no: 4, text: '方格图是否有黑影遮挡?', playSrcLeft: '/assets/Amsler/audios/4.4.0.mp3', playSrcRight: '/assets/Amsler/audios/4.4.1.mp3', playSrcHav: '/assets/Amsler/audios/4.4.2.mp3', }],
    };
  },

  mounted() {
    document.addEventListener("WeixinJSBridgeReady", function () {
      console.log("WeixinJSBridgeReady");
      document.getElementById('myAudio').play(); //视频自动播放
    }, false);
    if (window.name == 1) {
      window.name = 2;
      window.location.reload();
    }
    var video = document.querySelector('audio');
    document.addEventListener('visibilitychange', () => {
      // 当页面隐藏时候
      if (document.visibilityState === 'visible') {
        this.isPlay = false
        // 视频暂停
        // video.play();
      } else {
        // 当页面显示时视频开始播放
        video.pause();
      }
    });
  },
  created() {
  },
  watch: {
    currentIndex: {
      handler(newVal) {
        this.$refs.myAudio.load()
        if (this.$store.state.eyeText == '右眼') {
          this.playSrc = this.data[newVal].playSrcRight
        } else if (this.$store.state.eyeText == '左眼') {
          this.playSrc = this.data[newVal].playSrcLeft
        } else {
          this.playSrc = this.data[newVal].playSrc
        }
        this.$nextTick(() => {
          this.$refs.myAudio.play()
        })
      }
    }
  },
  beforeDestroy() {
    // clearInterval(this.timer);
    document.getElementById('myAudio').pause();
  },
  destroyed() {
    document.getElementById('myAudio').pause();
  },
  methods: {
    overAudio(e) {
      this.isPlay = false
      // this.$refs.myAudio.load();
      // this.$refs.myAudio.src=this.src
      // this.$refs.myAudio.play();
    },
    goNext() {
      if (this.currentIndex == 0) {
        this.currentIndex = 1;
      } else {
        this.curve = true;
        this.$refs.myAudio.load()
        this.playSrc = this.data[this.currentIndex].playSrcHav;
        this.$nextTick(() => {
          this.$refs.myAudio.play()
        })
      }
    },
    changeIndex(index) {
      this.curve = false;
      this.currentIndex = index;
    },
    upQuestion() {
      this.currentIndex = this.currentIndex - 1;
      this.curve = false;
    },
    async saveData() {
      let from = await this.$vlf.getItem('from');
      let userid = await this.$vlf.getItem('userId');
      var formData = new FormData()
      // formData.append('file', [])
      formData.append('VisualId', this.$route.query.visualId)
      formData.append('No', 0)
      formData.append('EyeType', this.$store.state.eyeText == '左眼' ? 1 : 2)
      formData.append('Color', null)
      formData.append('UserId', userid)
      formData.append('Status', 1)
      formData.append('Area', null);

      if (from) {
        await this.$httpProxy.post(`/${from}/CalcImageArea`, formData).catch(e => {
          console.log(e)
        })
      }
      this.$httpProxy.post('/api/visual/InsertMaculars', {
            VisualId: this.$route.query.visualId,
            No: 0,
            EyeType: this.$store.state.eyeText == '左眼' ? 1 : 2,
            // Image: [],
            Area: 0
          })
    },
    async setRightEye() {
      if (this.currentIndex == 0) {
        await this.saveData()
        if (this.$store.state.eyeText == '左眼') {
          this.$refs.myAudio.load()
          this.playSrc = this.data[0].playSrcRight
          this.$store.commit('set_eye_text', '右眼')
          this.$nextTick(() => {
            this.$refs.myAudio.play()
          })
        } else {
          this.$router.go(-1)
        }
      }
      if ([1, 2, 3].includes(this.currentIndex)) {
        this.$httpProxy.post('/api/visual/InsertMaculars', {
          VisualId: this.$route.query.visualId,
          No: this.currentIndex + 1,
          EyeType: this.$store.state.eyeText == '左眼' ? 1 : 2,
          Image: {},
          Area: 0
        }).then((res) => {
          if (res.data.Success) {
            if (this.currentIndex < 3) {
              this.currentIndex = this.currentIndex + 1
            } else {
              if (this.$store.state.eyeText == '左眼') {
                this.$store.commit('set_eye_text', '右眼')
                this.currentIndex = 0;
              } else {
                this.$router.go(-1);
              }
            }
          } else {
            this.$message.warning('出错了')
          }
        })
      }
    },
  },
};
</script>
 
<style lang="less" scoped>
@import "../../assets/less/vision.less";
</style>